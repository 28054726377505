import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'

export default function Alert({ type, list, children }) {

   let Icon, iconClass, textClass, containerClass
   switch (type) {
      case "success":
      default:
         Icon = CheckCircleIcon
         iconClass = 'text-green-400'
         textClass = 'text-green-700'
         containerClass = 'bg-green-50'
         break
      case "error":
         Icon = XCircleIcon
         iconClass = 'text-red-400'
         textClass = 'text-red-700'
         containerClass = 'bg-red-50'
   }

   if(!list?.length && !children) return false
   return (
      <div className={`rounded-md p-4 ${containerClass}`}>
         <div className="flex">
            <div className="flex-shrink-0">
               <Icon className={`h-5 w-5 ${iconClass}`} aria-hidden="true" />
            </div>
            <div className="ml-3">
               {
                  Array.isArray(list) ? (
                     <>
                        <div className={`text-sm ${textClass}`}>
                           <ul role="list" className={`space-y-1 ${list.length > 1 ? 'list-disc pl-5' : ''}`}>
                              {list.map(error => <li key={error}>{error}</li>)}
                           </ul>
                        </div>
                     </>
                  ) : (
                     <div className={`text-sm ${textClass}`}>
                        {list || children}
                     </div>
                  )
               }
            </div>
         </div>
      </div>
   )
}
