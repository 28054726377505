import useSWR from "swr"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"

import { useAuth } from "hooks/auth"
import { useNotifications } from "providers/notifications"

import Label from "components/Label"
import Input from "components/Input"
import Button from "components/Button"
import AuthLayout from "components/layouts/Auth"
import Alert from "components/Alert"

export default function Login() {

  // Access login 
  const { login } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: '/dashboard',
  })

  // Access router 
  const router = useRouter()
  const { token } = router.query

  // Get invite
  const { data: invite, error } = useSWR(token ? `/api/invites/${token}` : null, { refreshInterval: 0, revalidateOnFocus: false, revalidateIfStale: false });

  // Access notifications
  const notifications = useNotifications()

  // Local state 
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  /**
   * Load email
   */
  useEffect(() => {
    if (invite && !email) {
      setEmail(invite.email)
    }
  }, [invite])

  /**
   * Handle login form submission 
   */
  const submit = async (e) => {
    e.preventDefault()
    if (!email || !password) {
      notifications.error('Please enter your email and password.')
      return false
    }

    // Login 
    setSubmitting(true)
    const user = await login({ setErrors, setStatus, email, password, token })
    if (!user) setSubmitting(false)

  }

  return (
    <AuthLayout>
      <form onSubmit={submit}>
        <div>
          <h2 className="text-4xl font-bold text-gray-800 tracking-tight">Hello again</h2>
          <p className="text-gray-600 text-lg mt-2">Log into your account using your email and password.</p>
        </div>
        <div className="mt-8 space-y-4">
          <div>
            <Label>Email address</Label>
            <Input disabled={invite} size="lg" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
          </div>
          <div>
            <div className="flex justify-between items-center mb-1">
              <div>
                <Label noMargin>Password</Label>
              </div>
              <div>
                <Link href="/forgot-password">
                  <a className="text-sm text-gray-600 underline">Forgot your password?</a>
                </Link>
              </div>
            </div>
            <Input size="lg" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          </div>
        </div>
        {
          errors?.length > 0 && (
            <div className="mt-4">
              <Alert type="error" list={errors} />
            </div>
          )
        }
        <div className="flex flex-col space-y-4 mt-10">
          <Button color="black" type="submit" size="lg" disabled={submitting}>
            {submitting ? 'Logging in...' : 'Login'}
          </Button>
          <Button color="clear" href="/register" size="lg">I need to create an account</Button>
        </div>
      </form>
    </AuthLayout>
  )
}