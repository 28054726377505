import { ClipboardCopyIcon } from "@heroicons/react/outline"
import { CheckIcon } from "@heroicons/react/solid"
import { useNotifications } from "providers/notifications"
import { forwardRef } from "react"
import Label from "./Label"
import Spinner from "./Spinner"

const Input = forwardRef(({ id, autoComplete, size, label, required, disabled, placeholder, className = '', value, name, type, onKeyDown, multiline, loading, showCopy, readOnly = false, onSave, rows = 3, onClick = () => { }, onChange = () => { } }, ref) => {

   // Access notifications
   const notifications = useNotifications()

   // Determine size
   let sizeClass
   switch (size) {
      default:
         break
      case "lg":
         sizeClass = "py-3"
         break
   }

   // Compute classes
   const finalClass = `${className} ${sizeClass} ${(showCopy && !disabled) ? 'pr-10' : ''} ${disabled ? 'bg-gray-200 text-gray-400' : 'bg-white text-gray-800'} focus:ring-yellow-500 focus:border-yellow-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md`

   return (
      <div className="relative">
         {
            label && <Label htmlFor={id}>{label}</Label>
         }
         {
            multiline ? (
               <textarea id={id} required={required} autoComplete={autoComplete} readOnly={readOnly} disabled={disabled} value={value || ''} name={name} className={`${finalClass}`} onClick={onClick} rows={rows} placeholder={placeholder} onChange={onChange} onKeyDown={onKeyDown} />
            ) : (
               <input id={id} required={required} autoComplete={autoComplete} readOnly={readOnly} disabled={disabled} value={value || ''} name={name} className={`${finalClass}`} onClick={onClick} type={type || 'text'} placeholder={placeholder} onChange={onChange} onKeyDown={onKeyDown} />
            )
         }
         {
            loading && (
               <div className="absolute right-0 top-0 p-3">
                  <Spinner />
               </div>
            )
         }
         {
            (showCopy && !disabled) && (
               <div className="flex absolute right-0 top-0 bottom-0 p-1">
                  <button className="h-full aspect-square max-h-12 flex justify-center items-center bg-gray-100 text-gray-800 duration-150 rounded hover:bg-gray-200" onClick={() =>  {
                     navigator.clipboard.writeText(value)
                     notifications.success('Copied to clipboard')
                  }}>
                     <ClipboardCopyIcon className="w-4 h-4" />
                  </button>
               </div>
            )
         }
         {
            onSave && (
               <div className="flex absolute right-0 top-0 bottom-0 p-1">
                  <button className="h-full aspect-square max-h-12 flex justify-center items-center bg-yellow-100 text-yellow-600 duration-150 rounded hover:bg-yellow-200" onClick={onSave}>
                     <CheckIcon className="w-4 h-4" />
                  </button>
               </div>
            )
         }
      </div>
   )
})

export default Input