import Link from "next/link"
import { useAuth } from "hooks/auth"

export default function AuthLayout({ children, title, description }) {
   return (
      <div className="flex flex-col min-h-screen bg-gray-50">
         <div className="flex justify-center items-center border-b py-2">
            <Link href="/">
               <a>
                  <img src="/logo.png" width="150" alt="Yonderlife" />
               </a>
            </Link>
         </div>
         <div className="flex flex-1 items-center justify-center">
            <div className="w-screen max-w-xl mx-auto p-4 md:p-6 lg:p-8">
               {
                  (title || description) && (
                     <div className="sm:text-center mb-6">
                        {title && <h2 className="text-4xl font-bold text-gray-800 tracking-tight">{title}</h2>}
                        {description && <p className="text-gray-600 text-lg mt-2">{description}</p>}
                     </div>
                  )
               }
               {children}
            </div>
         </div>
      </div>
   )
}